import React, { Component } from 'react';
import './App.css';

// Navigation
import { BrowserRouter as Router, Route, Switch, NavLink as RRNavLink } from 'react-router-dom'
import { Collapse, Nav, Navbar, NavbarBrand, NavItem as RSNavItem, NavLink, NavbarToggler } from 'reactstrap';

import { Portfolio } from './component';

class NavItem extends Component {
  render() {
    const to = this.props.to;
    const title = this.props.title;
    return <RSNavItem>
      <NavLink exact to={to} tag={RRNavLink}>{title}</NavLink>
    </RSNavItem>
  }
}

class AppNavBar extends Component {

  constructor(props) {
    super(props);
    this.onToggleNavbar = this.toggleNavbar.bind(this);
    this.state = { collapsed: true };
  }

  toggleNavbar() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  renderBar() {
    return (
      <header>
        <Navbar expand="sm" color="light" light>
          <NavbarBrand to="/" tag={RRNavLink}>Portfolio</NavbarBrand>
          <NavbarToggler onClick={this.onToggleNavbar} className="mr-2" />
          <Collapse isOpen={!this.state.collapsed} navbar>
            <Nav navbar>
              <NavItem to="/" title="Home" />
              <NavItem to="/projects" title="Projects" />
            </Nav>
            <Nav navbar className="ml-auto">
              <NavItem to="/about" title="About" />
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    )
  }
  render() { return null; }
}

class AppFooter extends Component {
  render() {
    return <footer className="footer">
      <div className="container">
        <span className="text-muted">
          &copy; Illposed {(new Date()).getFullYear()}
        </span>
      </div>
    </footer>
  }
}

class App extends Component {

  componentDidMount() { this.props.doRetrieve() }

  componentWillReceiveProps(nextProps) {
    if (this.props.pages == null)
      nextProps.doRetrieve();
  }

  render() {
    return (
      <Router>
        <div>
          <Route component={AppNavBar} />
          <main role="main" className="container-fluid">
            <div key="gap">&nbsp;</div>
            <Switch>
              <Route exact path="/"
                render={p => <Portfolio key="portfolio" {...p} />} />
              <Route exact path="/works"
                render={p => <Portfolio key="portfolio" {...p} />} />
            </Switch>
          </main>
          <Route component={AppFooter} />
        </div>
      </Router>
    );
  }
}

export default App;
