import React, { Component } from 'react';
import {Col, Row} from 'reactstrap';

class Header extends Component {
  render() {
    return [
      <h1>Chandrasekhar Ramakrishnan</h1>,
      <Row>
        <Col sm={8} md={7} lg={6}>
          <p>Chandrasekhar Ramakrishnan is a media artist, data scientist, and programmer. His work attempts to ask and answer questions through data, sound, and images.</p>
        </Col>
      </Row>
    ]
  }
}

class JavaOsc extends Component {
  render() {
    return [
      <h2>JavaOSC <span class="timeactive">[2001-2003]</span></h2>,
      <Row>
        <Col>
          <p><a href="https://github.com/hoijui/JavaOSC">JavaOSC</a> is a Java framework for working with <a href="http://opensoundcontrol.org/">OpenSoundControl</a>.</p>
        </Col>
      </Row>
    ]
  }
}

class EtudesDuPiano extends Component {
  render() {
    return [
      <h2>Études du Piano <span class="timeactive">[2002]</span></h2>,
      <Row>
        <Col sm={8}><a href="http://illposed.bandcamp.com/album/tudes-du-piano"><img alt="Études du Piano" width="100%" src="/images/works/etudes-du-piano.jpg" /></a></Col>
      </Row>,
      <Row>
        <Col sm={8}>
          <p><a href="http://illposed.bandcamp.com/album/tudes-du-piano">Études du Piano</a>. A collection of pieces composed while studying electronic music with Curtis Roads and Stephen T. Pope at the University of California, Santa Barbara.</p>
        </Col>
      </Row>
    ]
  }
}

class Auracle extends Component {
  render() {
    return [
      <h2>Auracle <span class="timeactive">[2003-2004]</span></h2>,
      <Row>
        <Col sm={8}><a href="http://auracle.org"><img alt="Auracle" width="100%" src="/images/works/auracle.png" /></a></Col>
      </Row>,
      <Row>
        <Col sm={8}>
          <p><a href="http://auracle.org">An Internet sound installation</a> built
      in collaboration with Max Neuhaus, Jason Freeman, Kristjan Varnik, and Phil Burk.</p>
        </Col>
      </Row>
    ]
  }
}

class PatternAndRandom extends Component {
  render() {
    return [
      <h2>Pattern & Random Machine <span class="timeactive">[2004]</span></h2>,
      <Row>
        <Col sm={8}><a href="https://zkm.de/de/pattern-machine"><img alt="Pattern Machine" width="100%" src="/images/works/pattern-machine.jpg" /></a></Col>
      </Row>,
      <Row>
        <Col sm={8}>
          <p><a href="https://zkm.de/de/pattern-machine">Pattern Machine</a> and <a href="https://zkm.de/de/random-machine">Random Machine</a> are interactive installations which were developed for the ZKM <i>Algorithmic Revolution</i> exhibition. In collaboration with Ludger Brümmer and Götz Dipper.</p>
        </Col>
      </Row>
    ]
  }
}

class Zirkonium extends Component {
  render() {
    return [<h2>Zirkonium <span class="timeactive">[2004-2008]</span></h2>,
      <Row>
        <Col sm={8}><a href="https://github.com/ciyer/zirkonium"><img alt="Zirkonium" width="100%" src="/images/works/zirkonium.jpg" /></a></Col>
      </Row>,
      <Row>
        <Col sm={8}>
          <p>Configurable, multi-channel sound spatialization software originally developed for the 48-speaker <a href="https://zkm.de/de/ueber-das-zkm/organisation/hertz-labor/infrastruktur">Klangdom</a> at ZKM. Source code available at <a href="https://github.com/ciyer/zirkonium">github.com/ciyer/zirknoium</a>.
          </p>
        </Col>
      </Row>
    ]
  }
}

class Diglossia extends Component {
  render() {
    return [<h2>Diglossia <span class="timeactive">[2008-2009]</span></h2>,
      <Row>
        <Col sm={8}><a href="/documentation/diglossia.mp4"><img alt="Diglossia" width="100%" src="/images/works/diglossia.png" /></a></Col>
      </Row>,
      <Row>
        <Col sm={8}>
          <p>An environment for live-coded audio-visual performances. Described in detail in <a href="https://link.springer.com/chapter/10.1007/978-3-7091-0321-0_31">Artists-in-Labs Networking in the Margins</a>, with <a href="/documentation/diglossia.mp4">accompanying video</a>.</p>
        </Col>
      </Row>
    ]
  }
}

class PositusMusarum extends Component {
  render() {
    return [<h2>Positus Musarum <span class="timeactive">[2009-2010]</span></h2>,
      <Row>
        <Col sm={8}><img alt="Positus Musarum" width="100%" src="/images/works/positus-musarum.png" /></Col>
      </Row>,
      <Row>
        <Col sm={8}>
          <p>A sound installation presented at the <a href="https://www.kunstmuseumsg.ch/home.html">Kunstmuseum St. Gallen</a> as part of the <a href="http://www.mapping-museum-experience.com/en/results/artistic">eMotion</a> exhibition. <a href="http://illposed.com/music/Einklang/Files/20090617-Posmus-53-54-57-85-104.mp3">Listen to a sound excerpt.</a>
          </p>
        </Col>
      </Row>
    ]
  }
}


class Chronophasis extends Component {
  render() {
    return [<h2>Chronophasis <span class="timeactive">[2009-2010]</span></h2>,
      <Row>
      <Col sm={8}>
        <video controls width="100%"
          name="Chronophasis" src="/documentation/chronophasis.mov"
          type="video/mp4"></video>
      </Col>
    </Row>,
    <Row>
      <Col sm={8}>
        <p>A sound installation for the iPhone. Chronophasis creates visual and sonic patterns in time. Just like a clock creates visual patterns though the rotating of its hands, Chronophasis generates patterns that can be used to structure you day, week, month, or year.
        </p>
        <p>
        Listen to it and watch it at different times of day, different times of year, and in different places.
        </p>
      </Col>
    </Row>
    ]
  }
}


class OceanoNox extends Component {
  render() {
    return [<h2>Oceano Nox <span class="timeactive">[2010-2011]</span></h2>,
      <Row>
        <Col sm={8}>
          <a href="https://www.imdb.com/title/tt2004312/">
            <img alt="Oceano Nox" width="100%" src="/images/works/oceano-nox.jpg" />
          </a>
        </Col>
      </Row>,
      <Row>
        <Col sm={8}>
          <p>Music for <a href="https://www.imdb.com/title/tt2004312/">Oceano Nox</a>, a film by Georg Wasner. In the final version, the composition formed the basis of an improvisation by pianist Elaine Brennan.
          </p>
        </Col>
      </Row>
    ]
  }
}

class Kcrw extends Component {
  render() {
    return <h2>KCRW</h2>
  }
}


class EveryVessel extends Component {
  render() {
    return <h2>EveryVessel</h2>
  }
}

class Upcoming extends Component {
  render() {
    // return <h2>Upcoming</h2>
    return null;
  }
}


class Portfolio extends Component {
  render() {
    return [
      <Header />,
      <Upcoming />,
      <EveryVessel />,
      <Kcrw />,
      <OceanoNox />,
      <Chronophasis />,
      <PositusMusarum />,
      <Diglossia />,
      <Zirkonium />,
      <PatternAndRandom />,
      <Auracle />,
      <EtudesDuPiano />,
    ]
  }
}

export default Portfolio;
