import MarkdownPageState from './MarkdownPageState';

const SiteState = {
  retrieve: () => {
    return (dispatch) => {
      return MarkdownPageState.retrieve("/pages/home.md")(dispatch)
    }
  }
};

export default SiteState
